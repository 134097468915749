<template>
  <transition name="zoom-image">
    <div @click="closeZoom()" class="zoomContainer" v-if="zoomImage!==null">
      <dl>
        <dt>
          <a @click="closeZoom()"><i class="fa fa-close"></i></a>
        </dt>
        <dd v-if="zoomImage !== null">
          <div><img :src='zoomImage'></div>
        </dd>
      </dl>
    </div>
  </transition>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'zoom-container',
    data() {
      return {
        showZoom: false,
      };
    },
    computed: {
      ...mapState(['config', 'zoomImage']),
    },
    watch: {
      zoomImage(newValue) {
        console.log('New zoom image value: ', newValue);
        if (newValue === null) {
          $('meta[name=viewport]').attr(
            'content',
            'width=device-width, initial-scale=1, maximum-scale=1.0, '
              + 'minimum-scale=1.0, user-scalable=no',
          );
        } else {
          $('meta[name=viewport]').attr(
            'content',
            'width=device-width, initial-scale=1, maximum-scale=4.0, '
              + 'minimum-scale=1.0, user-scalable=yes',
          );
        }
      },
    },
    methods: {
      closeZoom() {
        this.$store.commit('setZoomImage', null);
      },
    },
  };
</script>

<style scoped lang="scss">
  .zoomContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000000;
    z-index: 999;
    transition: opacity .5s;

    dl {
      display: block;
      width: 100%;
      height: 100%;

      dt {
        display: block;
        width: 100%;
        color: #ffffff;
        text-align: right;
        height: 20px;

        a {
          color: #ffffff;
          font-size: 18px;
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }

      dd {
        display: block;
        height: calc(100% - 20px);
        width: 100%;
        justify-content: center;
        align-items: center;

        div {
          display: flex;
          height: 100%;
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }
    }

    img {
      width: 95%;
      height: 95%;
      object-fit: contain;
    }
  }

  .zoom-image-enter, .zoom-image-leave-to  {
    opacity: 0;
  }
</style>
